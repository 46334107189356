//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$font-roboto: 'Roboto', sans-serif !default;

/// @type List
$font-roboto-slab: 'Roboto Slab', sans-serif !default;

/// @type List
$font-open-sans: 'Open Sans', sans-serif !default;

/// @type List
$font-chantal: 'Chantal', sans-serif !default;

/// @type List
$icon-font-billsby: 'billsby-font' !default;

/// Regular font family
/// @type List
$text-font-stack: $font-roboto, 'Helvetica Neue Light', 'Helvetica Neue',
  'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
  'Monaco', monospace !default;

/// Copy text color
/// @type Color
$text-color: #222 !default;

/// Main brand color
/// @type Color
$brand-color: #243f5c !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../assets/' !default;

// Other Variables

$white: #fff;
$black: #000;
$black-100: #252422;
// Custom Colors
$brand-color-100: #243f5c;
$brand-color-200: rgba(0, 0, 0, 0.16);
$brand-color-300: #fe7f2d;
$brand-color-400: #25405d;
$brand-color-500: #0a2747;
$brand-color-600: #3a9b1b;
$brand-color-700: #f3f5fb;
$brand-color-800: #d22630;

$text-color-100: #c5c5c5;

$orange: #fe7f2d;
$orange-100: #e8752a;
