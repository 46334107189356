.data-card-holder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  &.iv-data-card-holder {
    .data-card {
      flex: 0 0 33.33%;
      max-width: 32.5%;
    }
  }
}

.data-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 23.5%;
  flex-direction: column;
  max-width: 23.5%;
  min-height: 159px;

  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 0 4px $brand-color-200;

  .heading-1 {
    margin-bottom: 1px;
  }

  .subheading-1 {
    margin-bottom: 16px;
  }

  .data-text-1 {
    margin-bottom: 0;
  }

  .iv-data-card-width {
    max-width: 40.5%;
  }
}
