main {
  margin-top: 74px;
}

*, 
*:before, 
*:after {
  margin: 0;
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.btn,
a {

  &.disabled {
    background-color: gainsboro;

    pointer-events: none;
  }

  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;

  @include on-event {
    outline: 0;
    box-shadow: none;
  }
}

/**
 * Basic styles for input fields
 */
input[type="email"],
input[type="password"],
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-focus-ring-color: none;
  outline: none;

  @include on-event {
    box-shadow: none;
  }
}

input,
select,
button,
textarea {
  outline: none;
  @include appearance(none);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @include appearance(none);
  }
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 50px;

  // @include respond-to(xl) {
  //   width: 1370px;
  // }

  // @include respond-to(lg) {
  //   width: 960px;
  // }

  // @include respond-to(md) {
  //   width: 720px;
  // }

  // @include respond-to(sm) {
  //   width: 540px;
  // }

  @include respond-to(xs) {
    width: 100%;
  }
}

.container-flex {
  display: flex;
  align-content: center;

  @include respond-to(sm-down) {
    display: block;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.custom-input {
  padding: 15px 21px;
  width: 100%;
  
  font-size: 21px;

  border: 0;
  border-radius: 4px;
}

.body-holder {
  display: flex;
  padding-top: 75px;

  background-color: $brand-color-700;
}

.notification-bar {
  & ~ .body-holder {
    padding-top: 149px;
  }

  & ~ .body-holder .sidebar,
  & ~ .body-holder .section-main {
    height: calc(100vh - 149px);
  }
}

.link {
  font-size: 17px;
  color: $brand-color;
  text-decoration: underline;

  span {
    font-size: 19px;
  }
}