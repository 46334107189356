.login {
  .section-hero {
    position: relative;

    height: 343px;
    padding: 45px 0 0;
    overflow: hidden;
    
    background-color: $brand-color-700;
    
    .container {
      position: relative;

      &:before {
        content: '';

        position: absolute;
        right: -205px;
        bottom: -126px;

        width: 868px;
        height: 400px;

        background-image: url('../../assets/images/banner.png');
        background-position: top right;
        background-repeat: no-repeat;
        background-size: 868px;
      }
    }
  }

  .section-heading {
    margin-bottom: 26px;
    width: 750px;
    max-width: 100%;

    font-family: "Roboto Slab";
    font-size: 36px;
    font-weight: 900;
    color: $black-100;
    line-height: 42px;
  }

  .section-text {
    width: 500px;
    margin-bottom: 19px;
    max-width: 100%;
    
    font-family: $font-roboto;
    font-size: 17px;
    line-height: 21px;
    color: $black-100;
    
    a {
      color: $brand-color-100;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .login {
    .section-hero .container:before {
      right: -300px;
    }
  }
}

@include respond-to(lg) {
  .login {
    .section-hero {
      padding: 45px 0 30px;
      height: auto;

      .container:before {
        right: -385px;
        bottom: -88px;
      }

      .section-heading {
        width: 580px;
      }

      .section-text {
        width: 454px;

        font-size: 16px;
      }
    }
  }
}

@include respond-to(md) {
  .login {
    .section-hero {
      padding-bottom: 20px;
      height: auto;

      .container:before {
        right: -310px;

        background-size: 615px;
      }
    }

    .section-heading {
      width: 415px;

      font-size: 30px;
    }

    .section-text {
      width: 400px;

      font-size: 15px;
    }
  }
}

@include respond-to(sm-down) {
  .login {
    .section-hero {
      height: 607px;

      .container:before {
        right: -104px;
        bottom: -430px;

        background-size: contain;
      }
    }

    .section-heading {
      width: 100%;

      font-size: 25px;
      line-height: 30px;
    }

    .section-text {
      width: 100%;

      font-size: 15px;
    }
  }
}

@include respond-to(xs) {
  .login {
    .section-hero {
      .container:before {
        bottom: -417px;
        right: 0;
        left: 0;

        margin: auto;
        width: 100%;

        background-size: contain;
      }
    }
  }
}

@media only screen and ( -webkit-min-device-pixel-ratio: 1.3 ),
  only screen and (    min--moz-device-pixel-ratio: 1.3 ),
  only screen and (      -o-min-device-pixel-ratio: 2.6/2 ), /* returns 1.3, see Dev.Opera */
  only screen and (         min-device-pixel-ratio: 1.3 ),
  only screen and ( min-resolution: 124.8dpi ),
  only screen and ( min-resolution: 1.3dppx ) {
  .login {
    .section-hero {
      .container {
        &:before {
          background-image: url('../../assets/images/banner@2x.png');
        }
      }
    }
  }
}