.filter-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &.mc-filter {
    .react-bootstrap-daterangepicker-container {
      position: relative;

      svg {
        position: absolute;
        top: 9px;
        right: 11px;

        width: 11px;
      }
    }

    .filter-head {
      padding-right: 32px;
      min-width: 231px;
      width: auto;
    }
  }
  &.pull-right {
    justify-content: flex-end;
  }
  &.no-margin {
    margin: 0;
  }

  .btn-orange {
    display: none;
  }

  .filter-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 11px;
    width: 231px;

    margin-left: 0px;

    font-size: 17px;
    color: $black-100;
    line-height: 23px;

    background-color: $white;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 4px $brand-color-200;

    &:after {
      display: none;
    }

    &.btn-secondary.dropdown-toggle,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      color: $black-100;

      background-color: $white;
      box-shadow: 0 0 4px $brand-color-200;
    }

    .fa-chevron-down {
      width: 12px;
      color: $black-100;
    }
    .icon-chevron-down {
      font-size: 12px;
    }
  }

  .filter-menu {
    width: 231px;
  }

  .icon-refresh {
    margin-right: 5px;
    width: 20px;
  }

  .dropdown-menu {
    border: none;
    box-shadow: 0 0 4px $brand-color-200;
  }

  .dropdown-item {
    padding: 7px 11px 7px 25px;
    margin-left: 0;
    font-size: 17px;
    color: $black-100;

    @include on-event() {
      outline: none;
    }
  }
}
