.logs-table {
  font-size: 18px;
  margin-top: 40px;
  .logs-table-title {
    font-weight: bold;
    margin: 0px -50px 0px -30px;
    padding: 20px 30px 20px 30px;
    background-color: rgb(230, 234, 245);
  }
  .logs-list {
    .logs-content {
      margin-top: 20px;
      overflow: hidden;
      border-radius: 4px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
      background-color: white;

      .logs-no-data {
        display: flex;
        justify-content: center;
        background-color: rgb(230, 234, 245);
        padding: 20px;
        font-size: 16px;
      }
      .logs-title {
        background-color: rgb(230, 234, 245);
        font-weight: bold;
        padding: 20px;
      }
      .logs-description {
        padding: 20px;
        line-height: 40px;
        .log-link {
          margin: 0;
          font-size: 17px;
          text-decoration: underline;
          color: #243f5c;
          cursor: pointer;
        }
      }
    }
  }
}
