.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background: #ffffffb6;
  // position: absolute;
}
