.daterangepicker {
  .ranges {
    li {
      &:hover {
        background-color: rgba(254,127,45,.28);
      }

      &.active {
        background-color: $brand-color-300;
      }
    }
  } 

  td {
    &.in-range {
      background-color: rgba(254,127,45,.28);
    }

    &.available:hover {
      background-color: rgba(254,127,45,.28);
    }

    &.active,
    &.active:hover {
      background-color: $brand-color-300;
    }
  }

  .btn-primary {
    background-color: $brand-color-300;
    border: 0;

    &:hover {
      background-color: $orange-100;
    }
  }
}