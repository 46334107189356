.back-link {
  display: inline-block;
  margin: 0px 0px 20px 0px;
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  color: #243f5c;
  cursor: pointer;
}

.customer-short-info {
  display: flex;
  .customer-score {
    background-color: #243f5c;
    color: white;
    font-weight: bold;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .customer-info {
    margin-left: 15px;
    line-height: 25px;
    .customer-name {
      font-weight: bold;
    }
    .customer-email {
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.customer-detailed-info {
  font-size: 18px;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  .info-header {
    background-color: rgb(230, 234, 245);
    font-weight: bold;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info-header-btn {
      border: 0;
      height: 40px;
      width: 100px;
      background-color: white;
      font-weight: bold;
    }
  }
  .info-content {
    padding: 30px 20px;
    .info-content-list {
      line-height: 40px;
      display: flex;
      b {
        width: 130px;
      }
    }
  }
}
