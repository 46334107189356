.section-main {
  padding: 20px 50px 30px 30px;
  width: 100%;
  height: calc(100vh - 75px);
  overflow: auto;

  &.in-life-customers {
    .top-bar {
      margin-bottom: 20px;
    }

    .wrapper {
      display: flex;
    }
    .export-container{
      display: flex;
      
      margin-right: 20px;
    }
    
  }

  .sub-section-title-divider {
    font-size: 17px;
    font-weight: bold;
    color: #243f5c;
    margin-bottom: 20px;
  }

  .sub-section {
    font-size: 17px;
    .sub-section-title {
      font-size: 17px;
      font-weight: bold;
      background-color: #f3f5fb;
      margin: -30px -30px 30px -30px;
      padding: 20px;
    }
  }
}
