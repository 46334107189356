header {
  position: fixed;
  top: -1px;
  left: 0;
  right: 0;
  z-index: 9;

  display: flex;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
  height: 75px;

  background-color: $brand-color;

  .container-flex {
    justify-content: space-between;
    align-items: center;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
  }

  a {
    @include on-event() {
      text-decoration: none;
    }
  }

  .logo-orig {
    width: 90px;
    margin-right: 11px;
    max-width: unset;
  }

  .nav-logo {
    display: flex;
    align-items: center;

    font-family: 'Roboto Slab';
    color: $white;
    line-height: 25px;
    font-size: 17px;
  }

  .latest-update {
    margin-bottom: 0;
    // margin-left: 50px;
    margin-left: 38px;

    font-size: 17px;
    color: $white;
    line-height: 25px;
  }
}

.notification-bar + header {
  top: 74px;
}

@include respond-to(sm-down) {
  header {
    padding-top: 23px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
