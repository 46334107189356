.notification-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  
  padding: 17px 0;

  background-color: $brand-color-300;

  &.hide {
    display: none;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .notification-message {
    margin-bottom: 0;
    
    font-family: $font-roboto;
    font-size: 17px;
    font-weight: 400;
    color: $white;
  }

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    opacity: .6;

    font-size: 24px;
    color: $brand-color-300;

    background-color: $white;
    border: 0;
    border-radius: 4px;
  }
}