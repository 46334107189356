body {
  font: 400 125% / 1.4 $text-font-stack;
  color: $black-100;
  line-height: 24px;
}

.heading-1 {
  font-size: 17px;
  font-weight: 700;
}

.subheading-1 {
  margin-bottom: 27px;

  font-size: 17px;
  font-weight: 400;
}

.data-text-1 {
  font-size: 36px;
  font-weight: 400;
}