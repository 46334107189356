.payment-info-container {
  display: flex;
  flex-direction: column;
  .payment-token {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
    letter-spacing: 0px;
  }
  .payment-name {
    font-size: 17px;
    font-weight: 500;
  }
}
.payment-error-container {
  margin-top: 20px;
  background-color: white;
  padding: 30px;
  border-radius: 4px;
  .payment-error-title {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 5px;
    letter-spacing: 0px;
  }
  .payment-error-message {
    font-size: 17px;
    letter-spacing: 0px;
  }
}

.payment-error-json {
  margin-top: 20px;
  background-color: white;
  padding: 30px;
  border-radius: 4px;
  .json-pretty {
    font-size: 17px;
  }
}
