.add-subscription-client-container {
  .section-main {
    .top-bar {
      margin-bottom: 20px;
    }
    .sub-section-title-divider {
      margin-top: 20px;
    }
  }
}
