.section-table {
  overflow: hidden;

  border-radius: 4px;
  box-shadow: 0 0 4px $brand-color-200;

  &.mc-chart {
    .tr {
      .th {
        font-weight: 700;

        &:not(:first-child) {
          color: inherit;

          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
    }

    .th,
    .td {
      flex: 0 0 calc(100% / 6);
      padding: 20px;
      max-width: calc(100% / 6);
    }
  }

  &.iv-chart {
    .tr {
      .th {
        font-weight: 700;

        &:not(:first-child) {
          color: inherit;

          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
    }

    .th,
    .td {
      flex: 0 0 calc(100% / 5);
      padding: 20px;
      max-width: calc(100% / 5);
    }

    .td {
      &:last-child {
        padding: 20px 20px 20px 0 !important;
      }
    }

    .cb-link {
      margin: 0;

      font-size: 17px;
      text-decoration: underline;
      color: $brand-color;

      cursor: pointer;
    }
  }

  &.ec-chart {
    .tr {
      &.no-data {
        padding: 25px;
        text-align: center;
        display: block;
        font-size: 16px;
      }
      .th {
        font-weight: 700;

        &:not(:first-child) {
          color: inherit;
          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
    }

    .th,
    .td {
      padding: 20px;

      &:first-child {
        flex: 0 0 10%;
      }

      &:nth-child(4) {
        flex: 0 0 15%;
      }

      &:last-child {
        flex: 0 0 15%;
      }

      flex: 0 0 30%;
      max-width: initial;
    }

    .cb-link {
      margin: 0;

      font-size: 17px;
      text-decoration: underline;
      color: $brand-color;

      cursor: pointer;
    }
  }

  &.ilc-chart {
    .tr {
      &.no-data {
        padding: 25px;
        text-align: center;
        display: block;
        font-size: 16px;
      }
      .th {
        font-weight: 700;

        &:not(:first-child) {
          color: inherit;
          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
    }

    .th,
    .td {
      padding: 20px;

      &:first-child {
        flex: 0 0 15%;
        max-width: 300px;
      }

      &:nth-child(3) {
        flex: 0 0 15%;
        max-width: 300px;
      }

      &:last-child {
        flex: 0 0 20%;
        max-width: 250px;
      }

      flex: 0 0 50%;
      max-width: initial;
    }

    .cb-link {
      margin: 0;

      font-size: 17px;
      text-decoration: underline;
      color: $brand-color;

      cursor: pointer;
    }
  }

  &.ilcv-chart {
    margin-top: 20px;

    .tr {
      &.no-data {
        padding: 25px;
        text-align: center;
        display: block;
        font-size: 16px;
      }
      .th {
        font-weight: 700;

        &:not(:first-child) {
          color: inherit;
          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
    }

    .th,
    .td {
      padding: 20px;

      flex: 0 0 50%;
      max-width: 250px;
    }
  }

  &.cb-chart {
    .tr {
      .th {
        font-weight: 700;

        &:not(:first-child) {
          color: inherit;

          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
    }

    .th,
    .td {
      flex: 0 0 calc(100% / 5);
      padding: 20px;
      max-width: calc(100% / 5);
    }
  }

  &.mrr-report {
    .tr {
      .th {
        font-weight: 700;

        &:not(:first-child) {
          color: inherit;

          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
    }

    .th,
    .td {
      &:first-child {
        flex: 0 0 40%;
      }
      flex: 0 0 30%;
      padding: 20px;
      max-width: none;
      font-family: 'Roboto';
      font-size: 17px;
      font-weight: 400;
      overflow-wrap: anywhere;
    }
  }

  &.partner-report {
    button {
      margin-left: 0;
      padding: 0;

      text-decoration: underline;
      cursor: pointer;

      font-weight: 400;
    }

    .tr {
      display: flex;
      .th {
        &:first-child {
          flex-grow: 1;
        }
        max-width: none;
        font-weight: 700;

        &:not(:first-child) {
          color: inherit;

          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
      .td {
        &:first-child {
          flex-grow: 1;
          max-width: none;
        }
      }
    }
  }

  // No data
  &.msc-lists,
  &.msc-queue-lists,
  &.msc-history-lists,
  &.batch-report,
  &.csv-report,
  &.mcc-queue-lists,
  &.mcc-lists,
  &.csv-report-migrated-customers,
  &.mc-batch-report,
  &.partner-report,
  &.mrr-report {
    .tr {
      &.no-data {
        padding: 25px;
        text-align: center;
        display: block;
        font-size: 16px;
      }
      .th {
        font-weight: 700;

        &:not(:first-child) {
          color: inherit;
          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
    }
    .cb-link {
      margin: 0;

      font-size: 17px;
      text-decoration: underline;
      color: $brand-color;

      cursor: pointer;
    }
  }

  &.msc-lists {
    .th,
    .td {
      padding: 20px;

      &:first-child {
        flex: 0 0 50%;
      }

      flex: 0 0 16%; // for every child
      max-width: initial;
    }
  }

  &.csv-report {
    .th,
    .td {
      padding: 20px;
      flex: 0 0 calc(100% / 4); // for every child
      max-width: initial;
    }
  }

  &.msc-queue-lists {
    .th,
    .td {
      padding: 20px;
      overflow: hidden;

      &:last-child {
        // flex: 0 0 10%;
        display: flex;
        justify-content: flex-end;
      }

      // flex: 0 0 20%; // for every child
      max-width: initial;
      // flex: 0 0 calc(100% / 4);
      // padding: 20px;
      // max-width: calc(100% / 5);
    }
  }
  &.msc-history-lists {
    .th,
    .td {
      padding: 20px;
      &:last-child {
        flex: 0 0 55%;
        display: flex;
        justify-content: flex-end;
      }
      flex: 0 0 15%; // for every child
      max-width: initial;
    }
  }

  &.mcc-lists {
    .th,
    .td {
      padding: 20px;

      &:first-child {
        flex: 0 0 50%;
      }

      flex: 0 0 calc(100% / 4); // for every child
      max-width: initial;
    }
  }

  &.mcc-queue-lists {
    .th,
    .td {
      padding: 20px;

      flex: 0 0 calc(100% / 4); // for every child
      max-width: initial;
    }
  }

  &.csv-report-migrated-customers,
  &.mc-batch-report {
    .th,
    .td {
      padding: 20px;
      flex: 0 0 calc(100% / 3); // for every child
      max-width: initial;
    }
  }

  &.mcc-lists {
    .th,
    .td {
      padding: 20px;

      &:first-child {
        flex: 0 0 50%;
      }

      flex: 0 0 calc(100% / 4); // for every child
      max-width: initial;
    }
  }

  .tr {
    display: flex;
    align-items: center;

    background-color: #fff;

    &:first-child {
      background-color: $brand-color-700;
    }

    .th {
      position: relative;

      &:not(:first-child) {
        color: $white;
      }

      &:not(:first-child):not(:last-child) {
        &:after {
          content: '';

          position: absolute;
          top: 0;
          right: -17px;
          z-index: 1;

          width: 0;
          height: 0;

          border-style: solid;
          border-width: 32px 0 32px 17px;
        }
      }

      &:nth-child(2) {
        background-color: $brand-color-800;

        &:after {
          border-color: transparent transparent transparent $brand-color-800;
        }
      }

      &:nth-child(3) {
        background-color: #d25726;

        &:after {
          border-color: transparent transparent transparent #d25726;
        }
      }

      &:nth-child(4) {
        background-color: #d27f26;

        &:after {
          border-color: transparent transparent transparent #d27f26;
        }
      }

      &:nth-child(5) {
        background-color: #d2b526;

        &:after {
          border-color: transparent transparent transparent #d2b526;
        }
      }

      &:nth-child(6) {
        background-color: #25a970;
      }
    }
  }

  .th,
  .td {
    display: flex;
    align-items: center;
    flex: 0 0 16.66%;
    padding: 20px;
    max-width: 16.66%;
    max-height: 64px;

    font-family: $font-roboto;
    font-size: 17px;
    font-weight: 400;

    overflow-wrap: anywhere;

    &:not(:nth-child(-n + 2)) {
      padding-left: 39px;
    }
  }

  .td {
    background-color: $white;
  }

  .cb-link {
    font-weight: 400;
    font-size: 17px;
    text-decoration: underline;
    color: #243f5c;
  }
}
