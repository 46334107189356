.customer-details {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .customer-info {
      margin-left: 0;
    }
  }

  &__movers {
    display: flex;

    .mover {
      &:last-child {
        margin-left: 20px;
      }
      width: 282px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 4px;
      box-shadow: 0px 0px 4px #00000029;
      display: flex;
      &__days {
        font-weight: bold;
        padding: 25px 40px;
        max-width: 102px;
        height: auto;
        display: flex;
        align-items: center;
        background: #f3f5fb 0% 0% no-repeat padding-box;
      }
      &__data-holder {
        padding: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
      &__data {
        &:first-child {
          margin-bottom: 5px;
        }
        color: #243f5c;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
      &__indicator-up {
        color: #25a970;
      }
      &__indicator-down {
        color: red;
      }
    }
  }
}
