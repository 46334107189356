.sectioncontent-container {
  display: flex;
  align-items: center;
  width: 100%;
  .content {
    width: 50%;
    .title {
      font-weight: bold;
      font-size: 21px;
    }
    .details {
      font-size: 17px;
    }
  }
  .columns-container {
    display: flex;
    width: 50%;
    padding-left: 30px;
    .column {
      flex: 0 0 calc(100% / 3);
      padding: 10px;
      max-width: calc(100% / 3);
      &.size-s {
        font-size: 14px;
      }
      &.size-m {
        font-size: 17px;
      }
      &.size-l {
        font-size: 22px;
        .column-title {
          color: #fe7f2d;
        }
      }
      .column-title {
        font-weight: bold;
      }
    }
  }
}
