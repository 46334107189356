.sidebar {
  flex: 0 0 21.4%;
  height: calc(100vh - 75px);
  max-width: 300px;

  background-color: $white;

  ul {
    padding-top: 20px;
    padding-left: 0;

    list-style: none;
  }

  h5 {
    margin-left: 15%;
    color: #fe7f2d;
  }

  .sidebar-link {
    display: inline-block;
    padding: 13px 10px;
    padding-left: 50px;
    width: 100%;

    font-family: $font-roboto;
    font-size: 17px;
    font-weight: 400;
    color: $black-100;

    &.is-active {
      font-weight: 700;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

