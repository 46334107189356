button {
  border: 0;
  padding: 10px;
  background-color: white;
  font-weight: bold;
  text-decoration: none;
  color: black;
  border-radius: 5px;
  margin-left: 15px;
}
.evaluation-company-info {
  line-height: 25px;
  .company-name {
    font-weight: bold;
  }
  .company-email {
    font-size: 15px;
    font-weight: 4 00;
  }
}
