.breakdown-close-tab {
  span {
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
    color: rgb(19, 19, 94);
  }
  margin-bottom: 15px;
}

.breakdown-main {
  .breakdown-title {
    font-weight: bold;
    margin-bottom: 15px;
  }
}
