.pipeline-age {
  .filter-wrapper {
    padding-right: 50px;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;

    font-size: 17px;

    .row-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      height: 60px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 15%;
        width: 15%;

        text-align: center;

        border-radius: 4px;
      }

      &:not(:first-of-type) {
        height: 100px;
      }

      .header {
        flex-direction: column;
        width: 160px;
        height: 100%;

        color: $white;

        p {
          margin-bottom: 0;

          &:first-of-type {
            font-weight: 700;
          }
        }

        &.signed-up {
          background-color: #c13838;
        }

        &.first-plan {
          background-color: #c35f35;
        }

        &.pre-live {
          background-color: #c4823b;
        }

        &.free-trial {
          background-color: #cdb647;
        }

        &.paying-customer {
          background-color: #53a675;
        }
      }

      .data-wrapper {
        flex-direction: column;

        background-color: $white;
        box-shadow: 0px 0px 4px #00000029;

        p {
          margin-bottom: 0;
        }

        .total-time {
          font-weight: 700;
        }
      }
    }
  }
}
