.scheduler-form {
  .form-group {
    display: flex;
    align-items: center;
    .title {
      width: 125px;
      margin: 0;
    }
    .form-datetime-picker {
      .react-datetime-picker__wrapper {
        border: 1px solid hsl(0, 0%, 80%);
        margin: 0 20px 0 0;
        border-radius: 5px;
        padding: 5px 10px;
        width: 320px;
      }
    }
    .form-content-select {
      width: 320px;
    }
  }
  .save-btn {
    font-size: 17px;
    padding: 10px 25px;
    background: #243f5c 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: white;
    margin: 0;
  }
}

.schedule-section {
  margin: 30px 0;
  background-color: #e7eaf3;
  margin: 0 -50px 30px -30px;
  padding: 0px 50px 30px 30px;

  .title {
    font-weight: bold;
    font-size: 18px;
    background-color: #d8dce7;
    margin: 0 -50px 30px -30px;
    padding: 10px 30px;
  }
}
