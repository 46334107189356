.failed-payment-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  // margin: 3px auto;
  .form-container {
    font-size: 18px;
    margin-top: 10px;
    // margin-left: 15px;
    // overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: rgb(255, 255, 255);

    .form-header {
      background-color: rgb(230, 234, 245);
      height: 63px;
      font-weight: bold;
      font-size: 17px;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .form-header-btn {
        border: 0;
        height: 40px;
        width: 100px;
        background-color: white;
        font-weight: 500;
        font-size: 17px;
        border-radius: 5px;
      }
    }
    .form-content {
      padding: 30px 20px;
      .form-content-list {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        b {
          width: 130px;
          font-size: 17px;
        }
        .form-content-choices {
          width: 100%;
          .form-content-select {
            width: 320px;
            .css-yk16xz-control {
              background-color: white;
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
              border: 0 !important;
              border-radius: 5px;
            }
          }
          .choice-btn {
            border: 0;
            height: 40px;
            width: 150px;
            background-color: white;
            font-weight: 500;
            font-size: 17px;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
            margin: 0px 20px 0px 0px;
            border-radius: 5px;
          }
          .choice-btn:focus {
            outline: 0;
          }
          .choice-active {
            border: #fe7f2d 2px solid !important;
          }
        }
        .form-content-choices-selected {
          border: #fe7f2d 2px solid;
        }
        .form-content-xfactor-slider {
          width: 100%;
          display: flex;
          align-items: center;
          .form-xfactor-counter {
            display: flex;
            align-items: center;
            border: 0;
            height: 50px;
            width: 100px;
            background-color: white;
            border-radius: 5px;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
            padding: 5px;
            margin-left: 20px;
          }
        }
      }
      .form-content-list:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &.form-error {
    .input-login,
    .input-check {
      display: none;
    }
  }

  &.form-check {
    .input-login,
    .input-error {
      display: none;
    }

    .container {
      padding: 0 15px;
    }
  }

  &.form-hide {
    .input-check,
    .input-error {
      display: none;
    }

    .input-login {
      display: block;
    }
  }

  .input-field {
    display: block;
    width: 85%;
    height: 40px;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-left: 20px;

    font-family: $font-roboto;
    font-size: 21px;
    line-height: 31px;
    color: $black-100;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #00000029;
    border-radius: 4px;
    opacity: 1;
    border-width: 0px;

    @include placeholder {
      color: $text-color-100;
    }

    @include on-event {
      box-shadow: 0px 0px 4px #00000029;
    }
  }
  .input-field:focus {
    box-shadow: 0px 0px 4px #00000029;
    border-color: black;
    border: solid 1px;
  }

  .input-error {
    background: #d22630 0% 0% no-repeat padding-box;

    @include on-event {
      background: #af1d25 0% 0% no-repeat padding-box;
    }
  }

  .input-check {
    background: #3b9b1b 0% 0% no-repeat padding-box;

    &:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 55px;
      width: 17px;
      height: 17px;

      background-image: url('../../assets/images/email.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    @include on-event {
      background: #328616 0% 0% no-repeat padding-box;
    }
  }

  .form-button {
    margin-top: 20px;
    padding: 9px;
    margin-left: 0px;
    height: 40px;

    color: $white;
    font-family: $font-roboto;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    background: $brand-color-100 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;

    cursor: pointer;

    transition: all 0.3s ease;
  }

  .form-error-message {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    font-weight: 500;
    font-size: 17px;
    color: #d22630;
  }
}

@include respond-to(sm-down) {
  form {
    width: 100%;

    .input-field {
      height: auto;
      padding: 8px 20px;
      font-size: 17px;
    }

    .input-submit {
      height: auto;
      padding: 10px 20px;

      font-size: 15px;
    }
  }
}
