.section-main {
  .sub-section {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    padding: 30px;
    &.section-1-update {
      padding: 0px;
      // display: flex;
      // justify-content: space-between;
      position: relative;
      overflow: hidden;
      .section-content {
        width: 80%;
        padding: 30px;
        z-index: 1;
        .section-title {
          color: #fe7f2d;
          font-weight: bold;
          font-size: 22px;
          margin-bottom: 20px;
        }
        p {
          text-align: justify;
        }
      }
      .section-image {
        position: absolute;
        right: -80px;
        width: 100%;
        top: 0;
        height: 100%;
        // max-height: 100%;
        // max-width: 300px;
        // width: 500px;
        // max-height: 500px;
        overflow: hidden;

        .image-billsbot {
          position: float;
          float: right;
          // padding-right: -20px;
          margin-bottom: -50px;
          // margin-top: -20px;
          // object-fit: cover;
          height: 110%;
        }
      }
    }
    &.section-2-target-chart {
      padding: 0;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0);
      .chart-target {
        .recharts-responsive-container {
          .recharts-wrapper {
            .recharts-surface {
              // padding: 2px;
            }
          }
        }
      }
    }
    &.dive-deeper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .section-title {
        font-weight: bold;
        font-size: 21px;
        margin-bottom: 10px;
      }
      .section-details {
        margin-bottom: 10px;
      }
      .tags-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .tag-group {
          display: flex;
          justify-content: center;
          .tag {
            font-weight: normal;
            font-size: 17px;
            padding: 10px;
            margin-left: 5px;
            margin-right: 5px;
            background: #243f5c 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: white;
          }

          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: 1700px) and (max-width: 1812px) {
  .section-content {
    width: 75% !important;
  }
}

@media (min-width: 1590px) and (max-width: 1700px) {
  .section-content {
    width: 70% !important;
  }
}

@media (min-width: 1500px) and (max-width: 1590px) {
  .section-content {
    width: 65% !important;
  }
}

@media (min-width: 1430px) and (max-width: 1500px) {
  .section-content {
    width: 50% !important;
  }
}

@media (min-width: 1361px) and (max-width: 1430px) {
  .section-content {
    width: 60% !important;
  }
  .section-image {
    right: -120px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1361px) {
  .section-content {
    width: 60% !important;
  }
  .section-image {
    right: -200px !important;
  }
}

@media (min-width: 1230px) and (max-width: 1300px) {
  .section-content {
    width: 60% !important;
  }
  .section-image {
    right: -250px !important;
  }
}
@media (min-width: 1150px) and (max-width: 1230px) {
  .section-content {
    width: 60% !important;
  }
  .section-image {
    right: -300px !important;
  }
}

@media (min-width: 1120px) and (max-width: 1150px) {
  .section-content {
    width: 60% !important;
  }
  .section-image {
    right: -320px !important;
  }
}

@media (max-width: 1120px) {
  .section-content {
    width: 100% !important;
  }
  .section-image {
    display: none;
  }
}
