.mmr-movers {
  .filter-wrapper {
    justify-content: flex-start;
    .btn-group {
      margin-right: 30px;
    }
  }

  .change-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .change-table-wrapper {
    flex: 0 0 48%;
  }
  .change-holder {
    svg {
      margin-right: 10px;
    }
    .positive {
      color: green;
    }
    .negative {
      color: red;
    }
  }
}
