.section-chart {
  padding: 20px 0;
  margin-bottom: 28px;

  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 0 4px $brand-color-200;

  &.chart-marketing {
    .recharts-wrapper {
      &:before {
        top: 19px;
        right: 79px;
        bottom: 49px;
      }
    }

    .custom-tooltip {
      padding: 22px 43px 18px 18px;

      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 0 4px $brand-color-200;

      h6 {
        margin-bottom: 16px;
        font-size: 17px;
      }

      .custom-tooltip-last-month {
        margin-bottom: 0px;
      }

      p {
        margin-bottom: 0;

        font-size: 17px;
      }
    }
  }

  &.chart-target {
    padding-left: 10px;
    // padding-right: 10px;
    .custom-tooltip {
      padding: 22px 43px 18px 18px;

      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 0 4px $brand-color-200;

      h6 {
        margin-bottom: 3px;
        font-size: 17px;
      }

      .custom-tooltip-last-month {
        margin-bottom: 0px;
      }

      p {
        margin-bottom: 0;

        font-size: 17px;
      }
    }
  }

  .recharts-wrapper {
    &:before {
      content: '';

      position: absolute;
      top: 5px;
      right: 29px;
      bottom: 35px;
      left: 79px;

      background: $brand-color-700;
    }
  }

  .recharts-surface {
    position: relative;
  }

  .recharts-legend-wrapper {
    display: none;
  }

  .recharts-cartesian-grid {
    background-color: $brand-color-700;
  }

  .recharts-cartesian-axis-tick {
    font-size: 17px;
  }

  .recharts-default-tooltip {
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 4px $brand-color-200;
  }

  .recharts-tooltip-item-name,
  .recharts-tooltip-item-separator {
    display: none;
  }

  .recharts-tooltip-item {
    margin-bottom: 6px;

    border-radius: 4px;

    &:nth-child(1) {
      background-color: #d22230;
    }

    &:nth-child(2) {
      background-color: #d25726;
    }

    &:nth-child(3) {
      background-color: #d27f26;
    }

    &:nth-child(4) {
      background-color: #d2b526;
    }

    &:nth-child(5) {
      background-color: #25a970;
    }
  }

  .recharts-tooltip-item-value {
    display: inline-block;
    padding: 5px 10px;
    min-width: 164px;

    font-size: 17px;
    color: $white;
  }

  .recharts-line-dot {
    display: none;
  }

  .recharts-active-dot,
  .recharts-line-dot {
    .recharts-dot {
      stroke-width: 0 !important;
    }
  }
}
