%btn-display {
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-in-out;

  @include on-event() {
    outline: none;

    text-decoration: none;
  }
}

%btn-typography {
  font-family: $font-roboto;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  line-height: 25px;
}

.btn-blue {
  @extend %btn-display;
  @extend %btn-typography;

  color: $white;

  background: $brand-color-100 0% 0% no-repeat padding-box;
  border-radius: 4px;

  @include on-event() {
    background-color: $brand-color-500;
  }
}

.btn-white {
  @extend %btn-display;
  @extend %btn-typography;

  padding: 8px 21px;

  color: $black-100;

  background-color: $white;
  border-radius: 4px;

  @include on-event() {
    color: $black-100;

    background-color: $brand-color-700;
  }
}

.export {
  @extend %btn-display;
  @extend %btn-typography;
  position: relative;
  padding: 0;
  button{
    margin: 0;
    padding: 0;
  }
  a {
    color: $black-100;
    display: inline-block;
    padding: 8px 21px;

    font-weight: 500 ;
    font-size: 17px;
  }

  background-color: $white;
  border-radius: 4px;

  @include on-event() {
    color: $black-100;

    background-color: $brand-color-700;
  }
}

.btn-orange {
  @extend %btn-display;
  @extend %btn-typography;

  padding: 8px 9px;

  font-weight: 400;
  color: $white;

  background-color: $brand-color-300;
  border: 1px solid $brand-color-300;
  border-radius: 4px;
}

.btn-full-width {
  width: 100%;
  margin: 0;
}

.btn-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
