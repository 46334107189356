.update-spend-data {
  form {
    width: 100%;
    margin: 28px auto;
  }
  
  .main-form-holder {
    padding-bottom: 13px;
    margin-bottom: 20px;
    width: 100%;
  
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 0 4px $brand-color-200;
  }

  .tr {
    display: flex;

    &:first-child {
      margin-bottom: 18px;

      background-color: $brand-color-700;
    }
  }
  
  .th,
  .td {
    flex: 0 0 20%;
    padding: 8px 20px;
    max-width: 20%;
    
    font-size: 17px;
    
    &:first-child {
      flex: 0 0 13%;
      max-width: 13%;
    }
  }

  .th {
    padding: 20px;

    font-weight: 700;
  }

  .input-wrapper {
    position: relative;

    span {
      position: absolute;
      top: 7px;
      left: 10px;
      z-index: 1;

      font-size: 14px;
    }

    input {
      padding: 5px 12px 5px 19px;

      font-size: 17px;

      background: $white;
      border: 1px solid $black-100;
      border-radius: 4px;
    }
  }
  
  .icon-refresh {
    margin-right: 5px;
    width: 20px;
  }
}