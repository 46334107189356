.scorecard-container {
  font-size: 18px;
  margin-top: 20px;
  // overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 255, 255);

  .scorecard-header {
    background-color: rgb(230, 234, 245);
    font-weight: bold;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .scorecard-header-btn {
      border: 0;
      height: 40px;
      width: 100px;
      background-color: white;
      font-weight: 500;
      font-size: 17px;
      border-radius: 5px;
    }
  }
  .scorecard-content {
    padding: 30px 20px;
    .scorecard-content-list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      b {
        width: 130px;
      }
      .scorecard-content-choices {
        width: 100%;
        .scorecard-content-select {
          width: 320px;
          .css-yk16xz-control {
            background-color: white;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
            border: 0 !important;
            border-radius: 5px;
          }
        }
        .choice-btn {
          border: 0;
          height: 40px;
          width: 150px;
          background-color: white;
          font-weight: 500;
          font-size: 17px;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
          margin: 0px 20px 0px 0px;
          border-radius: 5px;
        }
        .choice-btn:focus {
          outline: 0;
        }
        .choice-active {
          border: #fe7f2d 2px solid !important;
        }
      }
      .scorecard-content-choices-selected {
        border: #fe7f2d 2px solid;
      }
      .scorecard-content-xfactor-slider {
        width: 100%;
        display: flex;
        align-items: center;
        .scorecard-xfactor-counter {
          display: flex;
          align-items: center;
          border: 0;
          height: 50px;
          width: 100px;
          background-color: white;
          border-radius: 5px;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
          padding: 5px;
          margin-left: 20px;
        }
      }
    }
  }
}

.slidecontainer {
  width: 530px; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #f3f5fb;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #243f5c;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #243f5c;
  cursor: pointer;
}

@media screen and (max-width: 1300) {
  .choice-btn {
    margin-bottom: 20px;
    font-size: 10px;
  }
}
