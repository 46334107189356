.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin: -20px -50px 40px -30px;
  padding: 20px 50px 20px 30px;
  background-color: rgb(230, 234, 245);
  color: #243f5c;
  .bar-title {
    font-weight: bold;

    .link {
      font-size: 18px;
      cursor: pointer;
    }
  }
  .wrapper{
    display: flex;
  }

  .categories {
    display: flex;
    justify-content: space-between;
    span {
      cursor: pointer;
      margin-right: 30px;
    }
    z-index: 1;
    .category-selected {
      color: #fe7f2d;
      border-bottom: #fe7f2d 1px solid;
    }
  }
  .evaluation-title {
    font-weight: bold;
  }
  .evaluate-trial-btn {
    margin-left: 20px;
    .link-btn {
      display: block;
      border: 0;
      padding: 10px;
      background-color: white;
      font-weight: 500;
      text-decoration: none;
      color: black;
      border-radius: 5px;
      font-size: 17px;
    }
    z-index: 1;
  }
  .evaluation-btn-group {
    button {
      font-size: 17px;
      font-weight: 500;
    }
  }
}
