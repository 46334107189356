.section-table {
  overflow: hidden;

  border-radius: 4px;
  box-shadow: 0 0 4px $brand-color-200;

  &.scheduler-table {
    .tr {
      .th {
        font-weight: 700;
        background: none;
        color: black;

        &:not(:first-child) {
          color: inherit;

          background-color: inherit;

          &:after {
            display: none;
          }
        }
      }
      .no-data {
        display: flex;
        justify-content: center;
        flex: 0 0 calc(100% / 1);
        padding: 20px;
        max-width: calc(100% / 1);
      }
      .td {
        .label {
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1;
          width: 80px;
          padding: 5px;
          color: white;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          padding: 10px;
          &.label-success {
            background-color: rgb(11, 134, 11);
          }
          &.label-error {
            background-color: red;
          }
        }
      }
    }
  }

  &.table-columns-2 {
    .th,
    .td {
      flex: 0 0 calc(100% / 2);
      padding: 20px;
      max-width: calc(100% / 2);
    }
  }
  &.table-columns-3 {
    .th,
    .td {
      flex: 0 0 calc(100% / 3);
      padding: 20px;
      max-width: calc(100% / 3);
    }
  }
}
