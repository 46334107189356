.marketing-conversion {
  // .section-main {
  //   display: flex;
  //   flex-direction: column;
  // }

  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    img {
      margin-bottom: 32px;
      max-width: 315px;
    }

    h4 {
      font-size: 32px;
      font-weight: 700;
    }
  }
}