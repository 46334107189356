.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;

    list-style: none;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    width: 40px;
    height: 40px;

    border-radius: 4px;
    background: $white;
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);

    @include on-event {
      background: $brand-color;
      transition: .2s ease-in;

      a {
        color: $white;
      }
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 17px;

    color: $brand-color;

    @include on-event {
      text-decoration: none;
    }
  }

  .active {
   background: $brand-color;

    a {
      color: $white;
    }
  }

  .icon {
    font-size: 14px;
  }
}