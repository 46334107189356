.add-subscription-client-container {
  .section-main {
    .btn-blue {
      margin-top: 30px;
      font-weight: normal;
    }
    .error {
      color: red;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      font-size: 17px;
    }
  }
}
