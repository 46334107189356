form {
  width: 475px;
  margin: 50px auto;

  &.form-error {
    .input-login,
    .input-check {
      display: none;
    }
  }

  &.form-check {
    .input-login,
    .input-error {
      display: none;
    }

    .container {
      padding: 0 15px;
    }
  }

  &.form-hide {
    .input-check,
    .input-error {
      display: none;
    }

    .input-login {
      display: block;
    }
  }

  .input-field {
    display: block;
    width: 100%;
    height: 58px;
    padding: 15px 20px;
    margin-bottom: 20px;
    margin-left: 15px;

    font-family: $font-roboto;
    font-size: 21px;
    line-height: 31px;
    color: $black-100;

    border: 1px solid $black-100;
    background-color: $white;
    border-radius: 4px;

    @include placeholder {
      color: $text-color-100;
    }
  }

  .input-submit {
    position: relative;
    width: 100%;
    height: auto;
    padding: 8px;
    margin-bottom: 20px;

    color: $white;
    font-family: $font-roboto;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    line-height: 25px;

    background: $brand-color-100 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;

    cursor: pointer;

    transition: all 0.3s ease;

    &.hide-toast {
      display: none;
    }

    @include on-event() {
      background-color: $brand-color-500;
    }
  }

  .input-error {
    background: #d22630 0% 0% no-repeat padding-box;

    @include on-event {
      background: #af1d25 0% 0% no-repeat padding-box;
    }
  }

  .input-check {
    background: #3b9b1b 0% 0% no-repeat padding-box;

    &:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 55px;
      width: 17px;
      height: 17px;

      background-image: url('../../assets/images/email.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    @include on-event {
      background: #328616 0% 0% no-repeat padding-box;
    }
  }
}

.custom-form {
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0px;
    }
    .form-label {
      font-weight: bold;
      width: 200px;

      &.form-label-normal {
        font-weight: normal;
      }
    }
    .form-input {
      border: 0;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 4px #00000029;
      border-radius: 4px;
      opacity: 1;
      padding: 10px 15px;
      // width: 80%;
      flex-grow: 1;
    }
    .form-input-suffix {
      margin-left: 20px;
      width: 20%;
    }
  }
}

@include respond-to(sm-down) {
  form {
    width: 100%;

    .input-field {
      height: auto;
      padding: 8px 20px;
      font-size: 17px;
    }

    .input-submit {
      height: auto;
      padding: 10px 20px;

      font-size: 15px;
    }
  }
}
