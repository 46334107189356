.currency-breakdown {
  .back-link {
    display: inline-block;

    margin-bottom: 20px;

    font-weight: 400;
    font-size: 17px;
    text-decoration: underline;
    color: #243F5C;
  }

  h2 {
    margin-bottom: 20px;

    font-weight: 700;
    font-size: 22px;
    color: #252422;
  }
}